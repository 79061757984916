.pathHorizontal {
  stroke-dasharray: 1;
  stroke-dashoffset: 1;
  animation: dash 2s linear alternate infinite;
  animation-iteration-count: 1;
  animation-duration: 1000ms;
  animation-fill-mode: forwards;
}

.pathVertical {
  stroke-dasharray: 1;
  stroke-dashoffset: 1;
  animation: dash 2s linear alternate infinite;
  animation-iteration-count: 1;
  animation-duration: 1000ms;
  animation-fill-mode: forwards;
}

.pathVertical1 {
  stroke-dasharray: 1;
  stroke-dashoffset: 1;
  animation: dash 2s linear alternate infinite;
  animation-iteration-count: 1;
  animation-duration: 1000ms;
  animation-delay: 1000ms;
  animation-fill-mode: forwards;
}
