.party-wallpaper {
  background: linear-gradient(
      180deg,
      rgba(162, 222, 231, 0) 20%,
      rgba(162, 222, 231, 0.3) 20%,
      rgba(162, 222, 231, 0.3) 40%,
      rgba(162, 222, 231, 0.5) 40%,
      rgba(162, 222, 231, 0.5) 60%,
      rgba(162, 222, 231, 0.7) 60%,
      rgba(162, 222, 231, 0.7) 80%,
      rgba(162, 222, 231, 0.8) 80%
    ),
    linear-gradient(
      45deg,
      rgba(254, 254, 254, 0.3) 20%,
      rgba(254, 254, 254, 0.4) 20%,
      rgba(254, 254, 254, 0.4) 40%,
      rgba(254, 254, 254, 0.5) 40%,
      rgba(254, 254, 254, 0.5) 60%,
      rgba(254, 254, 254, 0.6) 60%,
      rgba(254, 254, 254, 0.6) 80%,
      rgba(254, 254, 254, 0.7) 80%
    ),
    linear-gradient(
      -45deg,
      rgba(162, 222, 231, 0) 20%,
      rgba(162, 222, 231, 0.3) 20%,
      rgba(162, 222, 231, 0.3) 40%,
      rgba(162, 222, 231, 0.5) 40%,
      rgba(162, 222, 231, 0.5) 60%,
      rgba(162, 222, 231, 0.7) 60%,
      rgba(162, 222, 231, 0.7) 80%,
      rgba(162, 222, 231, 0.8) 80%
    ),
    linear-gradient(
      90deg,
      rgba(254, 254, 254, 0) 20%,
      rgba(254, 254, 254, 0.4) 20%,
      rgba(254, 254, 254, 0.4) 40%,
      rgba(254, 254, 254, 0.6) 40%,
      rgba(254, 254, 254, 0.6) 60%,
      rgba(254, 254, 254, 0.8) 60%,
      rgba(254, 254, 254, 0.8) 80%,
      rgba(254, 254, 254, 0.9) 80%
    ),
    linear-gradient(
      -90deg,
      rgba(162, 222, 231, 0) 20%,
      rgba(162, 222, 231, 0.3) 20%,
      rgba(162, 222, 231, 0.3) 40%,
      rgba(162, 222, 231, 0.5) 40%,
      rgba(162, 222, 231, 0.5) 60%,
      rgba(162, 222, 231, 0.7) 60%,
      rgba(162, 222, 231, 0.7) 80%,
      rgba(162, 222, 231, 0.8) 80%
    ),
    linear-gradient(
      180deg,
      rgba(254, 254, 254, 0) 20%,
      rgba(254, 254, 254, 0.4) 20%,
      rgba(254, 254, 254, 0.4) 40%,
      rgba(254, 254, 254, 0.6) 40%,
      rgba(254, 254, 254, 0.6) 60%,
      rgba(254, 254, 254, 0.8) 60%,
      rgba(254, 254, 254, 0.8) 80%,
      rgba(254, 254, 254, 0.9) 80%
    );
  background-color: rgb(254, 254, 254);
  background-size: 100% 100%;
  min-height: 100%;
}
