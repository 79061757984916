.header {
  background: radial-gradient(
      circle,
      transparent 10%,
      #dfcfbe 10%,
      #dfcfbe 40%,
      transparent 40%,
      transparent
    ),
    radial-gradient(
      circle,
      transparent 10%,
      #dfcfbe 10%,
      #dfcfbe 40%,
      transparent 40%,
      transparent
    ),
    radial-gradient(
        circle,
        transparent 20%,
        #dfcfbe 20%,
        #dfcfbe 80%,
        transparent 80%,
        transparent
      )
      50px 50px,
    linear-gradient(#a8b1bb 2px, transparent 2px) 0 -1px,
    linear-gradient(90deg, #a8b1bb 2px, transparent 2px) -1px 0,
    linear-gradient(#a8b1bb 2px, transparent 2px) 0 -1px;
  background-color: #dfcfbe;
  background-size: 100px 100px, 100px 100px, 50px 50px, 50px 50px;
}
