.enter {
  animation: enter 1500ms linear alternate infinite;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}

@keyframes enter {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
}

.leave {
  animation: leave 1500ms linear alternate infinite;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}

@keyframes leave {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(100%);
  }
}

.table-enter {
  animation: table-enter 1500ms linear alternate infinite;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}

@keyframes table-enter {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
}

.appear {
  animation: appear 1000ms linear alternate infinite;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.disappear {
  animation: disappear 1000ms linear alternate infinite;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}

@keyframes disappear {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
