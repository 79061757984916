.angle-line-path {
  stroke-dasharray: 1;
  stroke-dashoffset: 1;
  animation: dash 1s linear alternate infinite;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}

.angle-curve-path1 {
  stroke-dasharray: 1;
  stroke-dashoffset: 1;
  animation: dash 1s linear alternate infinite;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 1000ms;
  animation-timing-function: ease-in-out;
}
.angle-curve-path2 {
  stroke-dasharray: 1;
  stroke-dashoffset: 1;
  animation: dash 1s linear alternate infinite;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 2000ms;
  animation-timing-function: ease-in-out;
}

.right-angle-path {
  stroke-dasharray: 1;
  stroke-dashoffset: 1;
  animation: dash 200ms linear alternate infinite;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 1000ms;
  animation-timing-function: ease-in-out;
}

@keyframes dash {
  from {
    stroke-dashoffset: 1;
  }
  to {
    stroke-dashoffset: 2;
  }
}
