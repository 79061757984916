.connecting-line-path {
  stroke-dasharray: 1;
  stroke-dashoffset: 1;
  animation: dash 1s linear alternate infinite;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
.node-appear {
  animation: appear 1000ms linear alternate infinite;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-delay: 1000ms;
}

.disappear {
  animation: disappear 1000ms linear alternate infinite;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}

.btn-appear {
  animation: appear 100ms linear alternate infinite;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes disappear {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes dash {
  from {
    stroke-dashoffset: 1;
  }
  to {
    stroke-dashoffset: 2;
  }
}
