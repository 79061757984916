.landing-page {
  background: linear-gradient(
      180deg,
      rgba(83, 114, 99, 0) 20%,
      rgba(83, 114, 99, 0.3) 20%,
      rgba(83, 114, 99, 0.3) 40%,
      rgba(83, 114, 99, 0.5) 40%,
      rgba(83, 114, 99, 0.5) 60%,
      rgba(83, 114, 99, 0.7) 60%,
      rgba(83, 114, 99, 0.7) 80%,
      rgba(83, 114, 99, 0.8) 80%
    ),
    linear-gradient(
      45deg,
      rgba(223, 207, 190, 0.3) 20%,
      rgba(223, 207, 190, 0.4) 20%,
      rgba(223, 207, 190, 0.4) 40%,
      rgba(223, 207, 190, 0.5) 40%,
      rgba(223, 207, 190, 0.5) 60%,
      rgba(223, 207, 190, 0.6) 60%,
      rgba(223, 207, 190, 0.6) 80%,
      rgba(223, 207, 190, 0.7) 80%
    ),
    linear-gradient(
      -45deg,
      rgba(83, 114, 99, 0) 20%,
      rgba(83, 114, 99, 0.3) 20%,
      rgba(83, 114, 99, 0.3) 40%,
      rgba(83, 114, 99, 0.5) 40%,
      rgba(83, 114, 99, 0.5) 60%,
      rgba(83, 114, 99, 0.7) 60%,
      rgba(83, 114, 99, 0.7) 80%,
      rgba(83, 114, 99, 0.8) 80%
    ),
    linear-gradient(
      90deg,
      rgba(223, 207, 190, 0) 20%,
      rgba(223, 207, 190, 0.4) 20%,
      rgba(223, 207, 190, 0.4) 40%,
      rgba(223, 207, 190, 0.6) 40%,
      rgba(223, 207, 190, 0.6) 60%,
      rgba(223, 207, 190, 0.8) 60%,
      rgba(223, 207, 190, 0.8) 80%,
      rgba(223, 207, 190, 0.9) 80%
    ),
    linear-gradient(
      -90deg,
      rgba(83, 114, 99, 0) 20%,
      rgba(83, 114, 99, 0.3) 20%,
      rgba(83, 114, 99, 0.3) 40%,
      rgba(83, 114, 99, 0.5) 40%,
      rgba(83, 114, 99, 0.5) 60%,
      rgba(83, 114, 99, 0.7) 60%,
      rgba(83, 114, 99, 0.7) 80%,
      rgba(83, 114, 99, 0.8) 80%
    ),
    linear-gradient(
      180deg,
      rgba(223, 207, 190, 0) 20%,
      rgba(223, 207, 190, 0.4) 20%,
      rgba(223, 207, 190, 0.4) 40%,
      rgba(223, 207, 190, 0.6) 40%,
      rgba(223, 207, 190, 0.6) 60%,
      rgba(223, 207, 190, 0.8) 60%,
      rgba(223, 207, 190, 0.8) 80%,
      rgba(223, 207, 190, 0.9) 80%
    );
  background-color: rgb(223, 207, 190);
  background-size: 100% 100%;
  min-height: 100%;
}

.sparkle {
  animation: sparkle 2000ms linear alternate infinite;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}

@keyframes sparkle {
  from {
    transform: scale(1) rotate(0deg);
    color: #0eb9e8;
  }
  50% {
    transform: scale(1.2) rotate(90deg);
    color: white;
  }
  to {
    transform: scale(1) rotate(180deg);
    color: #0eb9e8;
  }
}

.sparkle-footer {
  animation: sparkle-footer 2000ms linear alternate infinite;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}

@keyframes sparkle-footer {
  from {
    transform: scale(1) rotate(0deg);
  }
  50% {
    transform: scale(1.5) rotate(90deg);
  }
  to {
    transform: scale(1) rotate(180deg);
  }
}
