.path {
  stroke-dasharray: 1;
  stroke-dashoffset: 1;
  animation: dash 2s linear alternate infinite;
  animation-iteration-count: 1;
}

.line-path {
  stroke-dasharray: 1;
  stroke-dashoffset: 1;
  animation: dash 2s linear alternate infinite;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.curve-path {
  stroke-dasharray: 1;
  stroke-dashoffset: 1;
  animation: dash 2s linear alternate infinite;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-duration: 3s;
  animation-timing-function: ease-in-out;
}

.partial-curve-path {
  stroke-dasharray: 1;
  stroke-dashoffset: 1;
  animation: dash 2s linear alternate infinite;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-duration: 500ms;
  animation-delay: 2800ms;
  animation-timing-function: ease-in-out;
}

@keyframes dash {
  from {
    stroke-dashoffset: 1;
  }
  to {
    stroke-dashoffset: 2;
  }
}
