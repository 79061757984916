svg {
  height: 100%;
}
.uprightText {
  font-stretch: "50%";
  writing-mode: tb;
  text-orientation: upright;
}
.path {
  stroke-dasharray: 1;
  stroke-dashoffset: 1;
  animation: dash 2s linear alternate infinite;
  animation-iteration-count: 1;
}
@keyframes dash {
  from {
    stroke-dashoffset: 1;
  }
  to {
    stroke-dashoffset: 2;
  }
}
